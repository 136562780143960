import React, { useEffect } from "react";
import Link from "next/link";

import { useMenu, MenuTheme } from "@/context";
import { Container, Title, NavigationSpacer, Button, AnimateIn } from "@/components";

const NotFound = () => {
  const { setTheme } = useMenu();
  useEffect(() => {
    setTheme(MenuTheme.White);
  }, []);

  return (
    <AnimateIn>
      <NavigationSpacer />
      
      <div className="py-12 flex flex-col justify-center items-center lg:py-16">
        <Container>
          <Title>
            <span className="">Whoops! That page could not be found.</span>
          </Title>
          <p className="leading-extra-relaxed mt-6 md:text-lg">
            Sorry about that, if you are looking for something in particular
            please feel free to send me an email at{" "}
            <a
              href="mailto:hello@lukebrown.io"
              className="underline hover:no-underline"
            >
              hello@lukebrown.io
            </a>{" "}
            or visit my contact{" "}
            <Link href="/contact">
              <span className="underline hover:no-underline">page</span>
            </Link>
            .
          </p>
          <div className="mt-8 xs:flex xs:items-start xs:mt-10">
            <Button text="Get in touch" href="/contact" variant="PRIMARY" />
            <div className="mt-4 xs:ml-6 xs:mt-0">
              <Button text="Take me home" href="/" variant="SECONDARY" />
            </div>
          </div>
        </Container>
      </div>
    </AnimateIn>
  );
};
export default NotFound;
